<!--
File: TrafficSiteEditForm.vue
Description: from for adding/editing one traffic site info.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region" :initial_value="selected_region"
              @input='onRegionChange' :is_required="true" />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <RoadsDropdown :label="$t('label.select_road')" v-model="fk_road" data-vv-name="fk_road"
              :initial_value="fk_road" :is_required="true" :region_id="selected_region" />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <SectionsDropDown :label="$t('label.select_section')" v-model="fk_section" name="fk_section"
              data-vv-name="fk_section" :initial_value="fk_section" :road_id="fk_road" />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <md-field :class="[
              { 'md-valid': !errors.has('traffic_site_desc') }, { 'md-error': errors.has('traffic_site_desc') }
            ]">
              <label>{{ $t('stdCols.description') }}</label>
              <md-input v-model="traffic_site_desc" type="text" data-vv-name="traffic_site_desc" required
                v-validate="modelValidations.traffic_site_desc" />
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('start_distance') }, { 'md-error': errors.has('start_distance') }
            ]">
              <label>{{ $t('road_network.start_distance') }}</label>
              <md-input v-model="start_distance" type="Number" data-vv-name="start_distance" required
                v-validate="modelValidations.start_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('end_distance') }, { 'md-error': errors.has('end_distance') }
            ]">
              <label>{{ $t('road_network.end_distance') }}</label>
              <md-input v-model="end_distance" type="Number" data-vv-name="end_distance" required
                v-validate="modelValidations.end_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('surveyed_distance') }, { 'md-error': errors.has('surveyed_distance') }
            ]">
              <label>{{ $t('traffic.surveyed_distance') }}</label>
              <md-input v-model="surveyed_distance" type="Number" data-vv-name="surveyed_distance" required
                v-validate="modelValidations.surveyed_distance" />
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>{{ $t('traffic.aadt_coef_set') }}</label>
              <md-input v-model="fk_aadt_coef_set" type="Number" data-vv-name="fk_aadt_coef_set" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>{{ $t('traffic.sdr_distance') }}</label>
              <md-input v-model="sdr_distance" type="Number" data-vv-name="sdr_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import SectionsDropDown from '../Dropdowns/SectionsDropdown.vue'

  export default {
    name: 'traffic-sites-edit',
    filters: {
      dateFormat: function (value) {
        if (!value) return ''
        value = value.toString().substring(0, 19)
        return value
      }
    },
    data() {
      return {
        traffic_site_id: null,
        selected_region: null,
        fk_road: null,
        fk_section: null,
        traffic_site_desc: null,
        start_distance: null,
        end_distance: null,
        fk_aadt_coef_set: null,
        ts_guid: null,
        fk_vclass_preset: null,
        surveyed_distance: null,
        sdr_distance: null,
        geom: null,
        sdr_road: null,

        modelValidations: {
          fk_road: { required: true },
          fk_section: { required: true },
          traffic_site_desc: { required: true, min: 3 },
          start_distance: { required: true, numeric: true },
          end_distance: { required: true, numeric: true },
          surveyed_distance: { required: true, numeric: true },
        },
      }
    },
    components: {
      RegionsDropdown,
      RoadsDropdown,
      SectionsDropDown
    },
    props: {
      oper: String
    },
    created() {
      const { tid = null } = this.$route.params
      this.traffic_site_id = tid
      if (this.oper === 'upd' && tid) {
        this.$store.dispatch('LOAD_TRAFFIC_SITE_BY_ID', tid).then((res) => {
          const theSite = Array.isArray(res) ? res[0] : res

          this.$store.dispatch('LOAD_SECTION_BY_ID', theSite.fk_section).then(
            (res) => {
              const section = Array.isArray(res) ? res[0] : res
              this.selected_region = section.fk_region
            },
            (err) => {
              this.selected_region = 0
            }
          )

          this.fk_road = theSite.fk_road
          this.fk_section = theSite.fk_section
          this.traffic_site_desc = theSite.traffic_site_desc
          this.start_distance = theSite.start_distance
          this.end_distance = theSite.end_distance
          this.fk_aadt_coef_set = theSite.fk_aadt_coef_set
          this.ts_guid = theSite.ts_guid
          this.fk_vclass_preset = theSite.fk_vclass_preset
          this.surveyed_distance = theSite.surveyed_distance
          this.sdr_distance = theSite.sdr_distance
          this.geom = theSite.geom
          this.sdr_road = theSite.sdr_road
        })
      }
    },

    methods: {
      onClose() {
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/inventory_data/traffic')
      },
      onRegionChange() {
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          const alert = {
            type: 'error',
            text: this.$t(`traffic.traffic_site`) + this.$t(`label.was_not_saved`, {
              name_ru: this.traffic_site_desc,
              name_en: this.traffic_site_desc
            }),
            footer: ''
          }

          if (isValid) {
            const theSite = {
              fk_road: this.fk_road,
              fk_section: this.fk_section,
              traffic_site_desc: this.traffic_site_desc,
              start_distance: this.start_distance,
              end_distance: this.end_distance,
              fk_aadt_coef_set: this.fk_aadt_coef_set,
              ts_guid: this.ts_guid,
              fk_vclass_preset: this.fk_vclass_preset,
              surveyed_distance: this.surveyed_distance,
              sdr_distance: this.sdr_distance,
              geom: this.geom,
              sdr_road: this.sdr_road
            }
            const reqData = this.oper === 'add' ? theSite : { id: this.traffic_site_id, traffic_site: theSite }
            this.$store.dispatch(`${this.oper.toUpperCase()}_TRAFFIC_SITE`, reqData).then(
              () => {
                alert.type = 'success'
                alert.text = this.$t('traffic.traffic_site') + this.$t(`label.was_saved`, {
                  name_ru: this.traffic_site_desc,
                  name_en: this.traffic_site_desc
                })
                Swal.fire(alert).then(() => {
                  this.$nextTick(() => this.$validator.reset())
                  return
                })
              },
              (err) => {
                alert.footer = err
              }
            )
          } else {
            alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
          }
          Swal.fire(alert)
        })
        // To return back to the list
        this.$router.push('/inventory_data/traffic')
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
        /*
        return this.fk_road == null || this.fk_section == 0 || this.traffic_site_desc == null
          || this.start_distance == null || this.end_distance == null || this.surveyed_distance == null
        //|| this.fk_aadt_coef_set == null || this.ts_guid == null || this.fk_vclass_preset == null 
        //|| this.sdr_distance == null || this.geom == null || this.sdr_road == null*/
      }
    },
    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>